import { createAction, props } from '@ngrx/store'
import { PaymentInfo } from '../models/payer'

export const getPaymentInfo = createAction(
    '[Payer] Get payment info',
    props<{ centerId: number; isAutoPay: boolean }>()
)

export const getPaymentInfoSuccess = createAction(
    '[Payer] Get payment info with success',
    props<{ payment: PaymentInfo }>()
)

export const getPaymentInfoError = createAction(
    '[Payer] Error getting payment session',
    props<{ error: Error }>()
)

export const getAutoPaymentInfo = createAction(
    '[Payer] Get auto payment info',
    props<{ centerId: number; isAutoPay: boolean }>()
)

export const getAutoPaymentInfoSuccess = createAction(
    '[Payer] Get auto payment info with success',
    props<{ payment: PaymentInfo }>()
)

export const getAutoPaymentInfoError = createAction(
    '[Payer] Error getting auto payment session',
    props<{ error: Error }>()
)

export const clearPaymentInfo = createAction('[Payer] Clear Payment Info')
