import { Injectable } from '@angular/core'
import { FeatureService, PersonalizationGuard, selectBiometrics } from '@bh/security'
import { ParentAuthentication, selectCenterInfo, selectDependantList, selectProfile } from '@events'
import { Store } from '@ngrx/store'
import { combineLatest, Observable } from 'rxjs'
import { filter, map, take } from 'rxjs/operators'
import packageJson from '../../../../../package.json'
import { selectLoggedUser } from '@bh/session'

@Injectable({
    providedIn: 'root'
})
export class ParentPersonalizationGuard extends PersonalizationGuard {
    constructor(
        protected readonly featureService: FeatureService,
        protected readonly store: Store
    ) {
        super(featureService)
    }

    protected getPersonalizationAttributes(): Observable<{ [key: string]: any }> {
        const profile$ = this.store.select(selectProfile)
        const dependants$ = this.store.select(selectDependantList)
        const centers$ = this.store.select(selectCenterInfo)
        const biometrics$ = this.store.select(selectBiometrics)
        const loggedUser$ = this.store
            .select(selectLoggedUser)
            .pipe(map((user) => user as ParentAuthentication))

        return combineLatest([profile$, dependants$, centers$, biometrics$, loggedUser$]).pipe(
            filter(
                ([profile, dependents, centers]) =>
                    !!profile &&
                    !!dependents.length &&
                    dependents.every((dep) => !!centers[dep.centerId])
            ),
            take(1),
            map(([profile, dependents, centers, biometrics, loggedUser]) => {
                const centerModels: Array<string> = []
                const centerNumbers: Array<string> = []
                const dependentAges: Array<string> = []

                const dependentStages: Array<string> = []
                for (const dependent of dependents) {
                    const center = centers[dependent.centerId]
                    centerModels.push(center.model)
                    centerNumbers.push(center.centerNumber)
                    dependentStages.push(dependent.stage)
                    if (dependent.age) {
                        dependentAges.push(dependent.age)
                    }
                }

                return {
                    isBackupEligible: profile!.backupEligible,
                    isBiometricsEnabled: biometrics.isEnabled,
                    brightStarId: profile!.brightStarId,
                    centerModelList: centerModels,
                    centerNumberList: centerNumbers,
                    clientId: profile!.clientId?.toLocaleLowerCase(),
                    dependentAges: dependentAges,
                    dependentStages: dependentStages,
                    isCollegeCoachEligible: profile!.collgeCoachEligible,
                    isEdAssistEligible: profile!.edAssistEligible,
                    isElderCareEligible: profile!.elderCareEligible,
                    isFamilySupportEligible: profile!.familySupportEligible,
                    isBUCOnly: profile!.isParentOnlyBUC,
                    appVersion: packageJson.version,
                    secureAuthID: loggedUser.visitorID
                }
            })
        )
    }
}
