import moment from 'moment'

export class CurriculumPlans {
    id: string
    dependentId: string
    centerId: string
    attachmentId: string
    classroomId: string
    classroomName: string
    type: string
    stage: string
    date: Date
    created: string
    updated: string
    deleted: string

    constructor(data: any) {
        this.id = data.id
        this.dependentId = data.dependent_id
        this.centerId = data.center_id
        this.attachmentId = data.attachment_id
        this.classroomId = data.classroom_id
        this.classroomName = data.classroom_name
        this.type = data.type
        this.stage = data.stage
        this.date = moment(data.date).toDate()
        this.created = data.createdBy
        this.updated = data.updated
        this.deleted = data.deleted
    }
}
