import { HttpStatusCode } from '@angular/common/http'
import { APILogger, ConsoleLogger, LogLevel } from '@bh/logging'
import { AppInitService } from '../app/app-init.service'
import { ParentPersistentKeys } from '../../../events/src/lib/models/profile'
import { ArrivalNoteKeys } from '../../../events/src/lib/models/arrival-note'

export const defaultEnv = {
    name: 'local',
    production: false,
    helpUrl: 'https://bhlogin.brighthorizons.com/Account/HelpDesk?benefitid=5&fsTargetId=2&',
    learnMore:
        'https://bhfs-q-001.sitecorecontenthub.cloud/api/public/content/27f5a624c9f94b419b1a465166efe269?v=7a9d7983',
    legalPolicies: 'https://www.brighthorizons.com/privacy',
    learnAtHome: {
        infant: 'https://www.brighthorizons.com/resources/listing?q=%20topic:%20Infants',
        toddler:
            'https://www.brighthorizons.com/resources/listing?q=%20topic:%20Toddlers%20and%20Twos',
        twos: 'https://www.brighthorizons.com/resources/listing?q=%20topic:%20Toddlers%20and%20Twos',
        preschool: 'https://www.brighthorizons.com/resources/listing?q=%20topic:%20Preschool',
        school: 'https://www.brighthorizons.com/resources/listing?q=%20topic:%20Pre-K',
        nextsteps:
            'https://www.brighthorizons.com/resources/listing?q=%20topic:%20Elementary%20School'
    },
    resourcesAndInsights: {
        infant: 'https://www.brighthorizons.com/resources/Topics/Infants',
        toddler: 'https://www.brighthorizons.com/resources/Topics/Toddlers-and-Twos',
        twos: 'https://www.brighthorizons.com/family-resources?categoryFilters=Toddlers,%20Twos',
        preschool: 'https://www.brighthorizons.com/resources/Topics/Preschool-3-5',
        school: 'https://www.brighthorizons.com/resources/Topics/Pre-K',
        nextsteps: 'https://www.brighthorizons.com/resources/Topics/Elementary-Schools-5-11'
    },
    curriculumAtBrightHorizons: {
        infant: 'https://mydigimag.rrd.com/publication/?m=23917&i=610152&p=1&ver=html5',
        toddler: 'https://editiondigital.net/publication/?i=745521&p=&pn=',
        twos: 'https://editiondigital.net/publication/?i=745521&p=&pn=',
        preschool: 'https://mydigimag.rrd.com/publication/?i=745428&p=&pn=',
        Kindergarten: 'https://mydigimag.rrd.com/publication/?i=745512&p=&pn='
    },
    signaturePractices: {
        infant: 'https://mybrightday.brighthorizons.com/dashboard/documents/my-learners/SignaturePractices_InfantInfographic.pdf?foo=bar',
        toddler:
            'https://mybrightday.brighthorizons.com/dashboard/documents/my-learners/SignaturePractices_ToddlerInfographic.pdf',
        twos: 'https://mybrightday.brighthorizons.com/dashboard/documents/my-learners/SignaturePractices_TwosInfographic.pdf',
        preschool:
            'https://mybrightday.brighthorizons.com/dashboard/documents/my-learners/SignaturePractices_PreschoolInfographic.pdf',
        Kindergarten:
            'https://mybrightday.brighthorizons.com/dashboard/documents/my-learners/SignaturePractices_KindergartenInfographic.pdf'
    },
    schoolReadinessAtBrightHorizons: 'https://www.brighthorizons.com/programs/school-readiness',
    curriculumLearning: {
        infant: 'https://mydigimag.rrd.com/publication/?m=23917&i=610152&p=1&ver=html5',
        toddler: 'https://editiondigital.net/publication/?i=745521&p=&pn=',
        twos: 'https://editiondigital.net/publication/?i=745521&p=&pn=',
        preschool: 'https://mydigimag.rrd.com/publication/?i=745428&p=&pn=',
        school: 'https://mydigimag.rrd.com/publication/?i=745512&p=&pn=',
        Kindergarten: 'https://mydigimag.rrd.com/publication/?i=745512&p=&pn='
    },
    consentBanner: {
        iosID: '5e3bfd6e-dc42-448f-94c4-04b2492e6083',
        androidID: '6e484265-6d8d-4dc5-a897-97d82e2492c1',
        storageIdentifier: 'cdn-ukwest.onetrust.com',
        languageCode: 'en'
    },
    errorHandling: {
        maxRetries: 5,
        retryBaseDelay: 1000,
        timeoutMS: 60000
    },
    security: {
        appId: '',
        persistentKeys: [
            ...Object.values(ParentPersistentKeys),
            AppInitService.STORAGE_CURRENT_ENV,
            ...Object.values(ArrivalNoteKeys)
        ],
        production: true,
        ficEndpoint: '/api',
        authEndpoint: '/api/v2',
        gatewayHost: '/api',
        sessionRefreshTimeout: 0,
        remoteConfig: {
            configName: 'mbd.parent.config'
        },
        removeConnectedFilter: true
    },
    logging: {
        logLevel: LogLevel.INFO,
        appId: 'my-bright-day-parent',
        extraConfigs: {
            apiLoggerEndpoint: 'http://localhost/audit'
        },
        traceSessionList: [],
        transportList: [ConsoleLogger, APILogger]
    },
    remoteConfigRefreshTime: -1,
    timeoutMS: 60000,
    durationErrorToast: 5000,
    toastErrorStatus: [
        HttpStatusCode.InternalServerError,
        HttpStatusCode.Unauthorized,
        HttpStatusCode.RequestTimeout,
        HttpStatusCode.UnprocessableEntity,
        HttpStatusCode.ImATeapot
    ],
    codePushEnabled: false
}
