import { ActionReducer, createReducer, on, Action } from '@ngrx/store'
import {
    updateRefreshActions,
    removeAllRefreshActions,
    addRefreshAction,
    removeRefreshAction
} from './refresh-action.actions'

export const refreshActionFeatureKey = 'refresh-action'

export interface RefreshActionState {
    refreshActions: Action[]
}

const initialState: RefreshActionState = {
    refreshActions: []
}

export const RefreshActionReducer: ActionReducer<RefreshActionState> = createReducer(
    initialState,
    on(updateRefreshActions, (state: RefreshActionState, { actions }) => {
        return {
            ...state,
            refreshActions: actions
        }
    }),
    on(addRefreshAction, (state: RefreshActionState, { action }) => {
        const actions = [...state.refreshActions, action]
        return {
            ...state,
            refreshActions: actions
        }
    }),
    on(removeRefreshAction, (state: RefreshActionState, { action }) => {
        const actions = state.refreshActions.filter(item => item.type != action.type)
        return {
            ...state,
            refreshActions: actions
        }
    }),
    on(removeAllRefreshActions, (state: RefreshActionState) => {
        return {
            ...state,
            refreshActions: []
        }
    })
)
