export const enUS = {
    home: {
        pickup: 'Pick Up',
        checkin: 'Check In',
        checkout: 'Check Out',
        snapshot: 'Snapshot',
        learning: 'Learning at Home',
        todaysContact: 'Today’s Contact',
        atDate: 'at {{value}}',
        myEntry: 'My Entry',
        pickUpTime: 'Pickup Time at',
        witwht: 'What in the World Happened Today?',
        'developmental-observation': 'Developmental Observation',
        'teacher-notes': "Teacher's Note",
        'family-note': 'Family Note',
        'today-contact': 'Today’s Contact',
        'name-contact': 'Name: {{value}}',
        'phone-contact': 'Phone: {{value}}',
        'pickup-contact': 'Pickup Time: {{value}}',
        meals: 'Meals',
        meal: 'Meal',
        snack: 'Snack',
        bottle: 'Meal',
        breastfeeding: 'Meal',
        nap: 'Nap',
        sleep: 'Sleep',
        overnight: 'Overnight',
        bathroom: 'Bathroom',
        medicine: 'Medicine',
        toilet: 'Toilet',
        application: 'Application',
        pleaseBring: 'Please Bring',
        'default-activity': "It's a great day to learn!",
        'closed-center': 'See you soon for another great day!',
        unscheduled: 'Unscheduled',
        report: 'Daily Report',
        asleep: 'Asleep starting at {{sleepStart}}',
        'wake-time': 'Woke up at {{sleepEnd}}',
        'sleep-period-start': 'Slept for',
        'sleep-period-hours': '{{sleepDurationHours}} hr',
        'sleep-period-mins': '{{sleepDurationMinutes}} mins',
        'sleep-period-end': 'from {{sleepStart}} to {{sleepEnd}}',
        'rest-start': 'Started resting at {{restStart}}',
        'rest-end': 'Rested until {{restEnd}}',
        'rest-period-start': 'Rested for',
        reminders: 'Reminders',
        'no-reminders': 'No Reminders',
        'show-reminders': 'Show',
        'hide-reminders': 'Hide',
        'new-reminders': '{{count}} NEW',
        'bottle-amount':
            '{{foodContent}} Amount Offered {{amountOffered}}oz, Amount Taken {{amountTaken}}oz',
        'amount-taken-only': '{{foodContent}} Amount Taken {{amountTaken}}oz',
        'amount-offered-only': '{{foodContent}} Amount Offered {{amountOffered}}oz',
        'filter-group': 'Group',
        'filter-timeline': 'Timeline',
        'my-entry': 'My Entry',
        'last-meal': 'Last Meal',
        'food-provided': 'Food Provided',
        'insect-repellent': 'Insect repellent',
        sunscreen: 'Sunscreen',
        'applied-at': 'Applied at {{time}}',
        'received-at': 'Received at {{time}}',
        'not-applied-at-sunscreen': 'Did not apply sunscreen',
        'not-applied-at-repellent': 'Did not apply insect repellent',
        'not-received-meds': 'Did not receive meds',
        'will-not-receive-meds': 'Will not receive meds',
        'applied-at-no-time': 'Application',
        'received-at-no-time': 'Received',
        'needs-received': 'Needs to be received',
        'needs-to-be-received': 'Needs to be received at {{time}}',
        'health-check-reminder': {
            header: 'Health Check Reminder',
            message:
                'Please do not forget to complete the Health Check for today prior to dropping your children off.',
            later: 'Later',
            now: 'Do It Now'
        },
        'medicine-amount': '{{quantity}} {{unit}} of {{name}}',
        'medicine-applied': '{{name}} applied',
        dismiss: 'Dismiss',
        keep: 'Keep',
        'view-in-calendar': 'View in Calendar',
        'try-this': 'Try This: ',
        'learning-at-home-domains':
            'This supports your childs growth in the developmental domain of:',
        'more-information': 'More Information:',
        rest: 'Rest'
    },
    'activity-feed': {
        expand: 'Expand all',
        collapse: 'Collapse all',
        notes: 'Notes',
        snapshots: 'Snapshots',
        learning: 'Learning at Home',
        observations: 'Developmental Observations',
        pleaseBring: 'Please Bring',
        toDate: 'to {{value}}'
    },
    calendar: {
        snack: 'Snack Menu',
        bring: 'Please Bring',
        'classroom-activities': 'Classroom Activities',
        due: 'Due Date',
        'center-announcement': 'Center Announcement',
        closure: 'Center Closure',
        'staff-announcement': 'Staff Announcement',
        'marketing-announcement': 'Marketing Announcement',
        absence: 'Absence Reminder',
        'pdf-attachment': 'PDF Document',
        'image-attachment': 'Image'
    },
    'quick-actions': {
        'quick-actions-title': 'Quick Tasks',
        'health-check': 'Health Check',
        'arrival-notes': 'Arrival Notes',
        arrival: 'Arrival & Pick Up',
        absence: 'Mark Absence'
    },
    'center-info': {
        'email-address': 'Center Email',
        address: 'Center Address',
        hours: 'Operating Hours',
        'emergency-information': 'Emergency Information',
        'evacuation-locations': 'Evacuation Locations',
        'emergency-phone': 'Center Phone Number',
        'evacuation-phone': 'Phone Number',
        'call-cta': 'Call',
        evacuation: 'Evacuation Location',
        primary: 'Primary Evacuation Location',
        secondary: 'Secondary Evacuation Location',
        'phone-number': 'Phone Number',
        'evacuation-message':
            'In the event of a center emergency, severe weather closure/delayed opening, or an emergency in the community near the center, you will receive updates through the My Bright Day app. In certain instances where the center administration team is unable to provide ongoing updates, families will be asked to call this number to speak with a centrally located Bright Horizons team member.',
        'evacuation-number': '877-276-7113'
    },
    'health-check': {
        title: 'Health Check',
        intro: '{{centerName}} requires you to complete this each day for your household.',
        submit: 'Submit Health Check',
        complete: 'Health Check complete!',
        description: 'Please complete this check once per day, for your entire household.',
        'contact-us': 'Next Steps: Contact us at {{centerName}}',
        thanks: 'Thank you for completing the Health Check.',
        'contact-message':
            'Based on the answers you provided, please contact the center to discuss next steps prior to coming in.',
        'contact-email': 'Email our Center',
        'call-cta': 'Call {{phone}}',
        'confirmation-message':
            'By confirming, you are certifying all responses provided are true and accurate to the best of your knowledge.',
        'confirmation-button': 'Confirm',
        'cancel-button': 'Cancel'
    },
    'mark-absence': {
        title: 'Mark Absence',
        intro: 'Please select which children to mark absent and fill out the details below.',
        scheduledtitle: 'Scheduled Absences',
        scheduledtext: 'No absences are currently scheduled',
        newabsence: 'Please fill out the details below to submit an absence.',
        detailstitle: 'Absence Details',
        edittitle: 'Edit Absence',
        startdate: 'Start Date',
        returndate: 'Return Date',
        reason: 'Absence Reason',
        notes: 'Other Notes',
        complete: 'Absence Scheduled',
        submit: 'Submit Absence',
        adddetails: 'Add Absence Details',
        remove: 'Remove Absence',
        removeconfirmation: 'Do you want to delete this absence?',
        removeconfirm: 'Delete Absence',
        cancel: 'Cancel',
        save: 'Save Changes',
        reason_sick: 'Sick',
        reason_vacation: 'Vacation',
        reason_other: 'Other',
        edit: 'EDIT',
        'no-absences': 'No absences are currently scheduled.'
    },
    login: {
        welcome: 'Welcome!',
        title: 'My Bright Day',
        bh: 'Bright Horizons',
        'sign-in-message': 'Sign in with your Bright Horizons account',
        user: 'Username',
        'user-placeholder': 'Enter your username',
        pass: 'Password',
        'pass-placeholder': 'Enter your password',
        'login-cta': 'Log In',
        'forgot-user': 'Forgot username',
        'forgot-pass': 'Forgot password',
        'create-account-message': "Don't have an account?",
        'create-account-cta': 'Sign Up',
        'need-help-cta': 'Need Help?',
        'learn-more': 'Learn More',
        version: 'Version {{version}}',
        consentBanner: 'Cookie Settings',
        ok: 'OK',
        'credentials-missing-error': 'Username and Password required.',
        auto: {
            failed: 'Possible connection issue.  Please log out and back in to view most recent data.  Thank you!',
            reconnected: 'Connection is reestablished. Thank you!'
        },
        failed: 'Login Failed',
        invalid: 'Invalid username or password.',
        bio: {
            success: '{{biometryType}} is now enabled.',
            'enter-username':
                'Almost there! Enter your username and password to complete {{biometryType}} set up.',
            'quick-login-enable': 'Enable',
            'quick-login-dismiss': "Don't Enable",
            'quick-login-title': 'Quick Login with {{biometryType}}',
            'quick-login-message':
                'Use {{biometryType}} to sign into your account without entering your password.',
            'quick-login-question': 'Would you like to enable {{biometryType}} for “My Bright Day?”'
        },
        error: {
            header: 'Login Failed',
            invalidLogin: 'We are having trouble logging you in. Please try again later.',
            expiredSession: 'We encountered an issue. Please enter your credentials to re-login',
            SA001: 'Invalid username or password',
            SA002: 'Your account has been locked due to an excess amount of unsuccessful attempts. The account will stay locked for 30 minutes. Please wait and retry by logging in to your account again.',
            E001: 'You have not signed up for an account to access My Bright Day. Please sign up to continue',
            E002: 'Please check your inbox for an email from Bright Horizons and follow instructions contained within the email to confirm your account to access My Bright Day',
            E003: 'Your account has not been enabled to access My Bright Day. Please click on Need Help to contact our Support team for access.',
            E004: 'Your My Bright Day account is no longer active. Please click on Need Help to contact our Support team for access',
            E005: 'You must have a child enrolled at one of our center(s) to access My Bright Day'
        }
    },
    memories: {
        media: 'Photos',
        documents: 'Reports',
        'unit-note': 'Note',
        'unit-observation': 'Developmental Observation',
        'unit-report': 'Daily Report',
        'unit-portfolio': 'My Bright Journey Portfolio',
        'daily-report': 'Daily Report',
        reminders: 'Reminders',
        'download-not-available-header': 'Download Not Available',
        'download-not-available':
            'Due to Bright Horizon’s privacy policy this photo is not available to download at this time.',
        'privacy-warning-alert-title': 'Privacy Warning',
        'privacy-warning-alert-description':
            "There may be multiple people in this photo. Please consider the privacy of other parents who may not wish to have their child's image shared with others.",
        'pause-header': 'Gathering Your Memories!',
        'pause-subheader':
            'This might take a couple minutes! Feel free to keep using My Bright Day while these moments load.',
        imageAltText: 'Image',
        audioAltText: 'Audio',
        videoAltText: 'Video'
    },
    messages: {
        title: `{{name}}'s Teachers`,
        admins: 'Center Admins',
        brightHorizons: 'Bright Horizons',
        default: 'No Messages to display yet'
    },
    'messages-screen': {
        title: 'Messages',
        'end-option': `Filter {{count}}`
    },
    'unread-messages': {
        title: 'Most Recent',
        'caught-up-message': 'You’re all caught up!'
    },
    'messages-filter': {
        title: 'Filter',
        'show-results': 'Apply',
        reset: 'Reset',
        children: 'Children',
        centers: 'Centers'
    },
    documents: {
        note: 'Note',
        report: 'Daily Report',
        observation: 'Photos',
        snapshot: 'Photos',
        audio: 'Photos',
        video: 'Photos',
        developmentalobservation: 'Developmental Observation',
        reminders: 'Reminders',
        portfolio: 'My Bright Journey Portfolio'
    },
    arrival: {
        title: 'Arrival & Pick Up',
        message: 'Help us plan for your arrival at the center and let us know when you are here.',
        'check-in-action': 'Check In',
        'check-in-confirmed-action': 'Checked In',
        'check-out-action': 'Check Out',
        'check-out-confirmed-action': 'Checked Out',
        scheduled: 'Scheduled',
        unscheduled: 'Unscheduled',
        'check-in-submitted-parent': 'Check in submitted',
        'check-in-submitted-message': 'Check In Submitted',
        'check-in-submitted-center': 'Checked in by center',
        'arrival-eta': 'Estimated arrival at',
        'check-out-submitted-parent': 'Check out submitted',
        'check-out-submitted-message': 'Check Out Submitted',
        'check-out-submitted-center': 'Checked out by center',
        'set-eta-cta': 'Set Arrival Time',
        'im-here-cta': "I'm Here",
        'im-here-now-message': 'See you soon',
        'message-start': 'Thank you',
        'eta-complete-message': 'Thank you!',
        'eta-time-message': 'See you at {{eta}}',
        'im-here-message': 'Check In Submitted',
        'im-gone-message': 'Check Out Submitted',
        'arival-complete-message': 'Check {{arrivalType}} Submitted',
        'finish-cta': 'Done',
        'by-center': 'by center',
        absent: 'Marked Absent'
    },
    note: {
        title: 'Note',
        day: `{{name}}'s Day`
    },
    'arrival-notes': {
        expand: 'Expand All',
        collapse: 'Collapse All',
        title: 'Arrival Notes',
        message: 'Whose arrival notes would you like to submit?',
        'arrival-notes-editable-until': 'Editable until',
        'save-changes': 'Save Changes',
        'delete-notes': 'Delete Arrival Notes',
        'save-notes': 'Save Arrival Notes',
        'childnotes-title': 'Arrival Notes',
        'childnotes-liquids': 'Liquids',
        'childnotes-solids': 'Solids',
        'childnotes-meals': 'Meals',
        'childnotes-sleep': 'Sleep',
        'childnotes-bathroom': 'Bathroom',
        'childnotes-diaper': 'Diaper',
        'childnotes-toilet': 'Toilet',
        'childnotes-meds': 'Medicine',
        'childnotes-additional-notes': 'Additional Notes',
        'childnotes-submit': 'Submit',
        'childnotes-time-change': 'Time of last change?',
        'childnotes-did-go': 'When did they go?',
        'childnotes-type': 'Type',
        'childnotes-wet': 'Wet',
        'childnotes-urine': 'Urine',
        'childnotes-dry': 'Dry',
        'childnotes-bowel-movement': 'Bowel Movement',
        'childnotes-details': 'Details',
        'childnotes-tried': 'Tried',
        'childnotes-accident': 'Accident',
        'childnotes-diapercream': 'Diaper Cream?',
        'childnotes-yes': 'Yes',
        'childnotes-no': 'No',
        'childnotes-other-notes': 'Other Notes',
        'childnotes-previous': 'Previous',
        'childnotes-reset-answers': 'Reset Answers',
        'childnotes-add-food-item-placeholder': 'Default',
        'childnotes-food-all': 'All',
        'childnotes-food-most': 'Most',
        'childnotes-food-some': 'Some',
        'childnotes-food-none': 'None',
        'childnotes-overnight': 'Overnight',
        'childnotes-nap': 'Nap',
        'childnotes-wake-time': 'Wake time?',
        'childnotes-start-time': 'Start time?',
        'childnotes-end-time': 'End time?',
        'childnotes-notes-placeholder': 'Any notes to add?',
        'childnotes-bottle': 'Bottle',
        'childnotes-breastfeeding': 'Breastfeeding',
        'childnotes-when-eat': 'When did they eat?',
        'childnotes-what-eat': 'What did they eat?',
        'childnotes-add-food-item': 'Add food item',
        'childnotes-todays-food': "Today's Food",
        'childnotes-how-much': 'How much?',
        'childnotes-breastmilk': 'Breast milk',
        'childnotes-formula': 'Formula',
        'childnotes-milk': 'Milk',
        'childnotes-other': 'Other',
        'childnotes-submitted': 'Notes Submitted.',
        'childnotes-thanks': 'Thank you!',
        'childnotes-submission-failed': 'There was an error saving your entry.',
        'childnotes-Retry': ' Please try again',
        'pickup-title': 'Select Contact',
        'pickup-select': 'Select',
        'pickup-intro': 'Please select today’s contact for your child.',
        'pickup-phone': 'Phone Number',
        'pickup-time': 'Tentative Pick Up Time',
        'pickup-save': 'Save',
        'pickup-done': 'Done',
        'pickup-today': 'Today’s Contact',
        'pickup-today-reset': 'Reset Information',
        'received-medication': 'Did {{childName}} receive medication?',
        'when-applied': 'When was it applied?',
        'when-will-be-applied': 'When will it be applied?',
        'when-given': 'When was it given?',
        'when-will-be-given': 'When will it be given?',
        'will-receive-medication': 'Will {{childName}} receive medication?',
        'insect-repellent-applied': 'Does {{childName}} have insect repellent on?',
        'sunscreen-applied': 'Does {{childName}} have sunscreen on?',
        'previous-notes-list-title': 'Select Previous Note',
        cancel: 'Cancel',
        'previous-foods': 'Previous Foods',
        'add-items': 'Add',
        'cannot-edit': "It's past submission time for today's notes; they can no longer be edited",
        'can-edit': 'This note can be edited',
        'can-edit-until': 'This note can be edited until {{editableUntil}}',
        'edit-notes': 'Edit Notes',
        'editable-until': 'Editable until {{editableUntil}}',
        'notes-submitted': 'Notes Submitted',
        'save-note-reminder':
            'Leaving this page will not save new changes. Are you sure you want to exit?',
        'leave-child-notes': 'Yes',
        'stay-child-notes': 'No',
        'reset-answers': 'Reset Answers',
        AM: 'AM',
        PM: 'PM',
        'type-in-label': 'Type-In',
        'quick-add': 'Quick Add',
        now: 'Now',
        done:'Done',
        oz:'OZ',
        'breast_milk' : 'Breast milk',
        'formula': 'Formula',
        'milk': 'Milk',
        'other': 'Other'
    },
    settings: {
        title: 'Settings',
        notification: 'Notification Settings',
        email: 'Email Notifications',
        'email-subtitle': 'Sent to your primary email',
        push: 'Push Notifications',
        'push-subtitle': 'Shown on your device',
        legal: 'Legal Policies',
        privacy: 'Privacy Policy',
        care: 'Care Events',
        'care-subtitle': 'Updates on meals, naps, and more',
        general: 'General',
        'general-subtitle': 'Announcements, articles, and at-home learning',
        photosAndActivities: 'Photos and Activities',
        'photosAndActivities-subtitle': 'Photos, reports, and more',
        reminders: 'Reminders',
        'reminders-subtitle': 'Reminders and notices from the center',
        'email-notifications': 'Email Notifications',
        'push-notifications': 'General',
        'care-events': 'Care Events',
        version: 'App Version: {{version}}',
        'health-check-reminder': 'Health Check Reminders',
        'please-bring-reminder': 'Please Bring Reminders',
        logout: 'Log Out',
        cancel: 'Cancel',
        enabled: 'Enabled',
        disabled: 'Disabled',
        yes: 'Yes',
        no: 'No',
        'sign-in-preferences': 'Sign in Preferences',
        'bio-enabled': '{{biometryType}} is now enabled.',
        'bio-disabled': '{{biometryType}} is now disabled.',
        'logout-confirm': 'Are you sure you want to log out?',
        'turn-on-confirm': 'Are you sure you want to turn on {{biometryType}}?',
        'turn-off-confirm': 'Are you sure you want to turn off {{biometryType}}?',
        'photo-subtitle': 'Download all media from My Bright Day',
        'recent-download-subtitle': 'View a list of all Previous Downloads',
        subscriptions: {
            'all-general-notifications': 'Receive all general notifications',
            'all-email-notifications': 'Receive all email notifications',
            'all-care-events-notifications': 'Receive all care event notifications',
            checkin_email: 'Center Check In',
            checkout_email: 'Center Check Out',
            mcc_learning_at_home_email: 'Learning at Home',
            mcc_development_email: 'My Bright Journey Insights',
            mbj_portfolio_email: 'My Bright Journey Portfolio',
            center_announcement_push: 'Center Announcements',
            checkin_push: 'Center Check In',
            checkout_push: 'Center Check Out & Daily Report',
            center_closure_push: 'Center Closures',
            classroom_activity_push: 'Classroom Activities',
            daily_report_email: 'Daily Report',
            mcc_learning_at_home_push: 'Learning at Home',
            mapp_learning_at_home_push: 'Learning at Home',
            mbj_portfolio_push: 'My Bright Journey Portfolio',
            new_email_or_text_push: 'New Email or Text',
            mcc_development_push: 'My Bright Journey Insights',
            intraday_bathroom: 'Bathroom',
            intraday_meal: 'Meals',
            intraday_medication: 'Medications',
            intraday_nap: 'Naps',
            intraday_note: 'Teacher Notes',
            intraday_skin: 'Sunscreen/Insect Repellent',
            questionnaire_six: '6:00 AM Reminder',
            questionnaire_seven: '7:00 AM Reminder',
            questionnaire_eight: '8:00 AM Reminder',
            questionnaire_nine: '9:00 AM Reminder',
            please_bring_six: '6:00 AM Reminder',
            please_bring_seven: '7:00 AM Reminder',
            please_bring_eight: '8:00 AM Reminder',
            please_bring_nine: '9:00 AM Reminder',
            observation_push: 'Developmental Observations',
            media_push: 'Snapshots',
            observation_push_subtitle: 'Growth and development updates',
            media_push_subtitle: 'Pictures and notes from the classroom',
            curriculum_plan_email: 'Curriculum Plans',
            curriculum_plan_push: 'Curriculum Plans',
            bulk_download_email: 'Bulk Download Email',
            bulk_download_push: 'Bulk Download'
        }
    },
    account: {
        title: 'My Account',
        balance: 'My Balance',
        general: 'General',
        downloads: 'Downloads',
        request: 'Request Refund',
        refund: 'Refund Request',
        statement: 'View Statement',
        'more-actions': 'More Actions',
        'statement-subtitle': 'Tax and statement history',
        'current-balance': 'Current Balance',
        'payment-overdue': 'Payment Overdue',
        acknowledge: 'Acknowledge',
        decline: 'Decline',
        submit: 'Submit',
        cancel: 'Cancel ',
        ok: 'Ok',
        'photo-manager': 'Photo Manager',
        'recent-download': 'Recent Downloads',
        'tuition-title': 'Tuition Change Acknowledgment Required',
        'notification-acknowledged': 'Notification Acknowledged',
        'notification-declined': 'Notification Declined',
        'messages-acknowledged': 'Thank you! The selected message(s) have been acknowledged.',
        'messages-declined': 'Please enter reason for declining this tuition acknowledgment.',
        'messages-declined-success': 'Thank you! The selected message(s) have been declined.',
        'reason-text': 'Please let us know the reason for declining the tuition acknowledgement.',
        'reason-placeholder': 'Enter the reason here.',
        'manage-recurring-payments': 'Manage Recurring Payments',
        'set-up-recurring-payments': 'Set Up Recurring Payments',
        'make-payment': 'Make a Payment',
        'next-auto-draft': 'Next Auto Draft',
        'reminder-overdue':
            'You have an overdue payment at {{centerName}}. Please go <u>My Balance</u> to view details.',
        'recent-download-description':
            'Photo links can be viewed for up to one week after they are generated.',
        'view-link': 'View Link',
        'photo-manager-description':
            'Select the media type and time period to download images of your child. For privacy, group photos are not available for download.'
    },
    alerts: {
        close: 'Close',
        'title-emergency_alert': 'Emergency Alert',
        'title-please_bring': 'Please Bring',
        'title-classroom_activity': 'Classroom Activity',
        'title-center_announcement': 'Center Announcement',
        'title-center_closure': 'Center Closure',
        'title-absence': 'Absence Reminder',
        'title-snack_menu': 'Snack Menu',
        'title-due_date': 'Due Date',
        'title-upcoming_draft': 'Your Upcoming Recurring Payment',
        'title-recurring_draft': 'Your Recurring Payment',
        'title-upcoming_draft_today': 'Your Recurring Payment Today',
        'title-payment_overdue': 'Payment Overdue',
        'title-sleep_note_wakeup_time': 'Missing Data',
        'message-sleep_note_wakeup_time': 'Please provide a wake time for the Overnight entry',
        ok: 'OK'
    },
    domains: {
        'what-are-domains': 'What are Domains?',
        'what-are-domains-description':
            'Domains refer to a specific aspect of children’s development and growth, such as social-emotional, physical, or cognitive learning. While each child is unique in their rate of growth, children reach consistent developmental milestones. Each domain has several attributes, smaller components that describe key learning within that area. Lastly, each attribute has a series of progression points; observable examples that are reflective of a specific age and stage of development - demonstrating a child is learning the skills each respective attribute describes. These are sometimes called milestones.',
        emerging: 'Emerging',
        'emerging-description':
            'Young children’s skills and abilities slowly unfold, or emerge, during the first few years of life.',
        developing: 'Developing',
        'developing-description':
            'Young children continue to build, or develop, their skills and abilities.',
        progressing: 'Progressing',
        'progressing-description':
            'Children’s growth begins to accelerate because they’ve mastered foundational skills and abilities.',
        advancing: 'Advancing',
        'advancing-description': 'Children are trying, combining, and practicing many new things.',
        refining: 'Refining',
        'refining-description':
            'School-age children have achieved many developmental milestones, and are refining their skills.',
        'card-how-i-learn': 'How I Learn',
        'card-my-growing-body': 'My Growing Body',
        'card-how-i-communicate': 'How I Communicate',
        'card-my-growing-brain': 'My Growing Brain',
        'card-my-feelings-and-relationships': 'My Feelings & Relationships',
        'five-focus-areas': 'The 5 Focus Areas',
        'learn-about-domains': 'Learn about other Domains',
        'my-growing-body': {
            title: 'Domains: My Growing Body',
            header: 'My Growing Body',
            'my-growing-body-info':
                'My Growing Body is all about how children move their bodies and interact with the environment. Babies sit, crawl, and walk. Older children run, climb, jump, and balance. My Growing Body also refers to children’s hand and finger development (fine motor) and their sensory exploration.'
        },
        'my-feelings-and-relationships': {
            title: 'Domains: My Feelings & Relationships',
            header: 'My Feelings & Relationships',
            'my-feelings-and-relationships-info':
                'My Feelings and Relationships is all about how children see themselves and interact with others. Babies form trusting attachments with loving caregivers. Older children develop a sense of identity and belonging, understand their emotions, understand rules and routines, and form positive relationships with adults and other children.'
        },
        'how-i-communicate': {
            title: 'Domains: How I Communicate',
            header: 'How I Communicate',
            'how-i-communicate-info':
                'How I Communicate is all about how children communicate with others. Babies use cries, grunts, and gestures to communicate. Older children listen, ask questions, and respond in conversation. They learn social conversational rules, as well as early literacy skills.'
        },
        'my-growing-brain': {
            title: 'Domains: My Growing Brain',
            header: 'My Growing Brain',
            'my-growing-brain-info':
                'My Growing Brain is all about how children move from simple thinking patterns to more complex thought. Babies observe and learn about the world through their senses and through interactions. Older children use reasoning, solve problems, make comparisons, and think critically.'
        },
        'how-i-learn': {
            title: 'Domains: How I Learn',
            header: 'How I Learn',
            'how-i-learn-info':
                'How I Learn is all about how children develop the underlying brain operations necessary for daily life. Babies can focus their attention for a few minutes. Older children can remember information, follow directions, sustain their attention, and use creativity and imagination in their play.'
        }
    },
    learning: {
        'child-world-title': '{{child}}’s Growing World',
        'child-world-learn-more': 'Learn more about the',
        'child-world-curriculum-infant': ' Infant curriculum.',
        'child-world-curriculum-toddler': ' Toddler curriculum.',
        'child-world-curriculum-twos': ' Early Preschool curriculum.',
        'child-world-curriculum-preschool': ' Preschool curriculum.',
        'child-world-curriculum-school': ' School curriculum.',
        'world-observations': 'Recent Observations',
        'world-observations-description':
            'See how {{child}} is playing, learning, growing and practicing new skills.',
        'world-portfolio': 'My Bright Journey Portfolios',
        'world-portfolio-description': 'Highlights of {{child}}’s learning journey.',
        'world-curriculum': 'Curriculum Plans',
        'world-curriculumn-description': 'See what’s happening in {{child}}’s classroom this week.',
        'past-stage-title-not-enrolled-infant': '{{child}}’s Infant Days',
        'past-stage-title-not-enrolled-toddler': '{{child}}’s Toddler Days',
        'past-stage-title-not-enrolled-twos': '{{child}}’s Early Preschool Days',
        'past-stage-title-not-enrolled-preschool': '{{child}}’s Preschool Days',
        'past-stage-title-not-enrolled-school': '{{child}}’s School Days',
        'past-stage-title-enrolled-infant': '{{child}}’s Infant Days',
        'past-stage-title-enrolled-toddler': '{{child}}’s Toddler Years',
        'past-stage-title-enrolled-twos': '{{child}}’s Early Preschool Years',
        'past-stage-title-enrolled-preschool': '{{child}}’s Preschool Years',
        'past-stage-title-enrolled-school': '{{child}}’s School Years',
        'child-sub-not-enrolled-infant':
            'Curious about how infants learn and grow in our classrooms? Learn more about the',
        'child-sub-not-enrolled-toddler':
            'Curious about how toddlers learn and grow in our classrooms? Learn more about the',
        'child-sub-not-enrolled-twos':
            'Curious about how early preschoolers learn and grow in our classrooms? Learn more about the',
        'child-sub-not-enrolled-preschool':
            'Curious about how preschoolers learn and grow in our classrooms? Learn more about the',
        'child-sub-not-enrolled-school':
            'Curious about how children learn and grow in our classrooms? Learn more about the',
        'next-stage-inside-transition-infant': "We can't wait to see {{child}} in Infant!",
        'next-stage-inside-transition-toddler': "We can't wait to see {{child}} in Toddler!",
        'next-stage-inside-transition-twos': "We can't wait to see {{child}} in Early Preschool!",
        'next-stage-inside-transition-preschool': "We can't wait to see {{child}} in Preschool!",
        'next-stage-inside-transition-school': "We can't wait to see {{child}} in School!",
        'next-stage-outside-transition-infant': 'An Infant preview for {{child}}',
        'next-stage-outside-transition-toddler': 'A Toddler preview for {{child}}',
        'next-stage-outside-transition-twos': 'An Early Preschool preview for {{child}}',
        'next-stage-outside-transition-preschool': 'A Preschool preview for {{child}}',
        'next-stage-outside-transition-school': 'A School preview for {{child}}',
        'scheduled-header-infant': 'The countdown to Infant is on!',
        'scheduled-header-toddler': 'The countdown to Toddler is on!',
        'scheduled-header-twos': 'The countdown to Early Preschool is on!',
        'scheduled-header-preschool': 'The countdown to Preschool is on!',
        'scheduled-header-school': 'The countdown to School is on!',
        'child-sub-inside-transition':
            "We've got everything you need for the next step. For a preview, check out the ",
        'child-sub-outside-transition':
            'To see how your child will continue the learning journey, explore our ',
        'our-curriculum': 'Our Curriculum',
        'our-curriculum-subtitle': 'What is the assessment and planning process?',
        withdrawn: 'Withdrawn',
        'list-screen-title': 'My Bright Journey Portfolios',
        'no-observations-single':
            'There are no developmental observations for your child during the {{stage1}} program.',
        'no-observations-double':
            'There are no developmental observations for your child during the {{stage0}} and {{stage1}} programs.',
        'no-observations-multiple':
            'There are no developmental observations for your child during the {{stage0}}, and {{stage1}} programs.',
        'for-parents-infant': 'For Infant Parents',
        'for-parents-toddler': 'For Toddler Parents',
        'for-parents-twos': 'For Early Preschool Parents',
        'for-parents-preschool': 'For Preschool Parents',
        'for-parents-school': 'For School Parents',
        'for-parents-next steps': 'For Next Steps Parents',
        'learn-at-home': 'Learn at Home',
        'explore-activities-for-child': 'Explore activities for {{child}}',
        'resources-and-insights': 'Resources and Insights',
        'shared-from-our-experts': 'Shared from our experts',
        'next-steps-header': 'We know {{child}} will continue to shine!',
        'next-steps-sub':
            'Grade school will be here before you know it - along with much more learning and growing.',
        'ready-set-grow': 'READY, SET, GROW!',
        'email-center': 'Email the Center',
        'transition-ready-infant':
            '{{child}} is almost ready for Infant! Questions about moving up?',
        'transition-ready-toddler':
            '{{child}} is almost ready for Toddler! Questions about moving up?',
        'transition-ready-twos':
            '{{child}} is almost ready for Early Preschool! Questions about moving up?',
        'transition-ready-preschool':
            '{{child}} is almost ready for Preschool! Questions about moving up?',
        'transition-ready-school':
            '{{child}} is almost ready for School! Questions about moving up?',
        'transition-ready-next steps':
            '{{child}} is almost ready for Next Steps! Questions about moving up?',
        'email-us': 'or email us.',
        'learn-more': 'Learn more',
        'ready-set-grow-title': 'What’s next for {{child}}?',
        'ready-set-grow-description-infant': 'Welcome to infant!',
        'ready-set-grow-description-toddler': 'Welcome to toddler!',
        'ready-set-grow-description-twos': 'Welcome to early preschool!',
        'ready-set-grow-description-preschool': 'Welcome to preschool!',
        'ready-set-grow-description-school': 'Welcome to school!',
        'ready-set-grow-description-next steps': 'Welcome to next steps!',
        'ready-set-grow-description': "Here's some helpful information for this next big step.",
        questions: 'Questions?',
        'curriculum-title': 'Curriculum at Bright Horizons',
        'curriculum-description':
            'Learn how Bright Horizons’ World curriculum supports your child’s love of learning, curiosity, and whole-child development.',
        pdf: 'pdf',
        'signature-practices-title': 'Signature Practices',
        'signature-practices-description':
            'Discover Signature Practices, just some of the ways Bright Horizons ensures high-quality learning and nurturing experiences for your child every day.',
        'school-readiness-title': 'School Readiness at Bright Horizons',
        'school-readiness-title-description':
            'How school readiness goes beyond traditional academic learning and incorporates future forward skills, providing a foundation for downstream success.',
        pagination: 'Please wait...',
        domains: {
            title: 'Domains',
            'how-i-learn': 'How I Learn',
            'my-growing-body': 'My Growing Body',
            'how-i-communicate': 'How I Communicate',
            'my-growing-brain': 'My Growing Brain',
            'my-feelings-and-relationships': 'My Feelings & Relationships'
        },
        stages: {
            infant: 'Infant',
            toddler: 'Toddler',
            twos: 'Early Preschool',
            preschool: 'Preschool',
            school: 'School'
        },
        observations: {
            title: 'Developmental Observations',
            'details-title': 'Developmental Observations',
            'what-developmental-observations': 'What are Developmental Observations?',
            'view-by-domains': 'View by Domains',
            'view-by-domains-description':
                'Find Developmental Observations related to specific learning domains.',
            'domain-title-growing-body': 'My Growing Body',
            'domain-subtitle-growing-body':
                'Discover how {{child}} is growing through movement and play.',
            'domain-title-feelings-relationships': 'My Feelings & Relationships',
            'domain-subtitle-feelings-relationships':
                'A look into {{child}}’s emotional development.',
            'domain-title-communicate': 'How I Communicate',
            'domain-subtitle-communicate': 'Get insight into {{child}}’s language development.',
            'domain-title-growing-brain': 'My Growing Brain',
            'domain-subtitle-growing-brain': 'Learn more about {{child}}’s cognitive development.',
            'domain-title-how-learn': 'How I Learn',
            'domain-subtitle-how-learn': 'See how {{child}} is learning and practicing new skills.',
            'what-are-domains': 'What are Domains?',
            'view-by-child-age-stage': 'View by {{child}}’s Stage',
            recent: 'Recent',
            'see-all': 'See All',
            'what-are-observations-description':
                'As teachers talk and play with children, they observe children’s behavior and learning to understand where children are developmentally. From this information, teachers plan experiences that support children’s growth to the next level.',
            'explore-domains': 'Explore Domains',
            'age-group': 'Age Group',
            'show-observations': 'Show Observations',
            cancel: 'Cancel',
            filter: 'Filter',
            reset: 'Reset'
        },
        'privacy-warning-alert-title': 'Privacy Warning',
        'privacy-warning-alert-description':
            "There may be multiple people in this photo. Please consider the privacy of other parents who may not wish to have their child's image shared with others."
    },
    'assessment-planning': {
        'assessment-planning': 'The Assessment and Planning Process',
        'assessment-planning-title': 'What is the Assessment and Planning Process?',
        'paragraph-one': {
            description:
                'Bright Horizons’ assessment, planning, and portfolio process includes 3 steps:',
            'line-one': 'Teachers observe children’s interests and needs.',
            'line-two':
                'Teachers use their observations to develop flexible, engaging lesson plans for where children are right now and where they’re going next.',
            'line-three': 'Teachers then document, assess, and communicate learning with families.'
        },
        'paragraph-two':
            'How do teachers know what to assess? Our approach to documenting children’s growth and development over time (assessment) is deeply rooted in current research about children’s development. Teachers receive robust training in understanding and assessing children’s growth. ',
        'paragraph-three': {
            description:
                'There are five Learning Domains, or overarching areas of development that are a part of My Bright Journey:',
            'line-one': 'My Growing Body (Physical Development)',
            'line-two': 'My Feelings & Relationships (Social and Emotional Development)',
            'line-three': 'How I Communicate (Language & Literacy Development)',
            'line-four': 'My Growing Brain (Cognitive Development)',
            'line-five': 'How I Learn (Approaches to Learning) '
        },
        'paragraph-four':
            'Each of these domains has several Attributes -- smaller components that describe key learning within that area.',
        'paragraph-five':
            'Lastly, each attribute has a series of Progression Points (or milestones) -- observable examples that are reflective of a specific age and stage of development.',
        'paragraph-six':
            'Although we know development happens in a relatively predictable sequence, each child is on their own developmental journey. As such, we recognize that children will move through progression points in their own time. We honor the natural course of individual development, special abilities, and the cultural contexts unique to each child.'
    },
    'curriculum-plans': {
        title: 'Curriculum Plans',
        'title-curriculum-plan': 'Curriculum Plan: Week of {{date}}',
        sort: 'Sort by Date',
        'filter-title': 'Filter',
        cancel: 'Cancel',
        center: 'Center',
        'show-results': 'Show Results',
        'week-of': 'Week of {{date}}'
    },
    offline: {
        title: 'Something Went Wrong',
        subTitle: 'Please check your connectivity and try again later.'
    },
    dailyReport: {
        share: 'share',
        download: 'download',
        downloadSuccess: 'Daily Report downloaded successfully'
    },
    'photo-manager': {
        'media-type': 'Media Type',
        period: 'Period',
        'select-period': 'Select a time period up to one year.',
        'start-date': 'Start Date',
        'end-date': 'End Date',
        select: 'Select',
        'select-start-date': 'Select Start Date',
        'select-end-date': 'Select End Date',
        'generate-link': 'Generate Link',
        'view-link': 'View Link',
        'link-generation-success-header': 'A link to your photos is being generated.',
        'link-generation-success-detail':
            "Larger date ranges may take longer; feel free to navigate elsewhere while the link is loading. You will be notified when it's available.",
        media: {
            all_media: 'All Media',
            audio: 'Audio',
            photo: 'Photos',
            video: 'Video'
        },
        'photo-item': {
            custom: 'Custom',
            'last-30-days': 'Last 30 Days',
            'last-3-month': 'Last 3 Months',
            'last-6-month': 'Last 6 Months'
        },
        'available-link-count':
            'A total of <b>{{count}} photos</b> are available to download for 1 week.',
        'available-link-message': 'This link can be shared via email, messaging, and more.',
        'download-link': 'Download Link',
        'share-link': 'Share Link',
        'no-data': 'No data available for this selected timeframe.',
        'download-success':
            'Your Download is now complete and available in your devices download files.',
        'download-error':
            'Your download could not be completed.  Please ensure there is enough space on your phone and try again.'
    },
    'app-update-alert': {
        'title': 'Upgrade to Latest App Version Needed',
        'message': 'We have noticed that you are using an unsupported version of the My Bright Day app, which is not fully compliant with the latest app features and updates. <span>Please upgrade to the latest app version on {{marketPlace}}. Failure to do so will result in inaccessibility of the app.</span>',
        'close': 'Close',
        'update': 'Update'
    }
}
