import { NoteColor } from '@bh/design-system'

export class Notification {
    id: string
    guardianId: string
    type: NotificationType
    noteId: string
    message: string
    isRead: boolean
    isMessage: boolean
    isReminder: boolean
    isDeleted: boolean
    readInMessages: boolean
    readInReminders: boolean
    sortDate: Date
    color: NoteColor
    startDate?: string
    endDate?: string

    dependentId?: string
    calendarId?: string
    centerId?: string
    centerName?: string
    title?: string

    showInHomepage: boolean
    attachmentIds: Array<string>

    constructor(data: any) {
        this.id = data.id
        this.guardianId = data.guardian_id
        this.type = data.notification_type || data.type // workaround for handling new-or-old notifications contract
        this.noteId = data.note_entry_id
        this.message = data.message
        this.isRead = !!data.read
        this.isMessage = !!data.show_in_messages
        this.isReminder = !!data.show_in_reminders
        this.showInHomepage = !!data.show_in_homepage
        this.readInMessages = !!data.read_in_messages
        this.readInReminders = !!data.read_in_reminders
        this.isDeleted = !!data.deleted
        this.sortDate = data.created ? new Date(data.created) : new Date(data.updated)
        this.color = getColor(this.type)
        this.dependentId = data.dependent_id
            ? data.dependent_id
            : data.dependentId
            ? data.dependentId
            : data.dependent_ids
            ? data.dependent_ids[0]
            : ''
        this.calendarId = data.calendar_entry_id || this.id
        this.centerId = data.center_id
        this.centerName = data.centerName
        this.title = data.title
        this.startDate = data.calendar_entry_start_date || this.sortDate?.toISOString()
        this.attachmentIds = getAttachmentIds(data)
        this.endDate = data.calendar_entry_end_date || ''
    }
}

function getColor(type: NotificationType): NoteColor {
    switch (type) {
        case 'emergency_alert':
            return 'red'
        case 'please_bring':
        case 'classroom_activity':
        case 'due_date':
            return 'gray'
        case 'center_announcement':
        case 'center_closure':
            return 'green'
        case 'absence':
            return 'purple'
        case 'upcoming_draft':
            return 'green'
        case 'recurring_draft':
            return 'green'
        case 'upcoming_draft_today':
            return 'green'
        case 'payment_overdue':
            return 'green'
        default:
            return 'yellow'
    }
}

export type NotificationType =
    | 'emergency_alert'
    | 'please_bring'
    | 'center_announcement'
    | 'center_closure'
    | 'classroom_activity'
    | 'staff_announcement'
    | 'snack_menu'
    | 'due_date'
    | 'email_note'
    | 'sms_note'
    | 'mcc_general'
    | 'mcc_my_bright_journey_insight'
    | 'mcc_learning_at_home'
    | 'absence'
    | 'teacher_note'
    | 'upcoming_draft'
    | 'recurring_draft'
    | 'upcoming_draft_today'
    | 'payment_overdue'

function getAttachmentIds(data: any): Array<string> {
    return (data.attachment_id ? [data.attachment_id] : data.attachment_ids) || []
}

export type MsNotification = { emergency_alerts: Notification[]; reminders: Notification[] }
